// FUNÇÕES/MÓDULOS SITE
// import initCarousel from '../modules/initCarousel';

// initCarousel();

// let paginate = $('#page').val();

// $("#nextList").on("click", function () {
//     paginate++;
//     let route = $("#blogRoute").attr("href");
//     window.location.replace(`${route}?page=${paginate}`);
// });

// $("#previousList").on("click", function () {
//     paginate--;
//     let route = $("#blogRoute").attr("href");
//     window.location.replace(`${route}?page=${paginate}`);
// });
   // ORDENA LISTAGEM DE FARMÁCIAS DA API
   $('body').on('change', '.listOrder', function (event) {
    event.preventDefault();

    var order = $('.listOrder').val();
    var select = $(this);
    var result = select.closest('.buy-resultDetails').find('.resultSearch-item');
    var list = result.find('.buy-item');
    var array = [];
    $.each(list, function (index, val) {
        array.push(val);
    });

    if (order == 'desc') {
        array.reverse();
        result.html(array);
    } else {
        array.reverse();
        result.html(array);
    }
});

